<template>
  <base-section
    id="info-alt"
    dark
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <div class="ml-5">
            <v-responsive>
              <base-img
                :src="require(`@/assets/logo-2.png`)"
                contain
                max-width="200"
                width="100%"
              />
            </v-responsive>
            <h3>IT решения для вашего бизнеса</h3>
            <p>полный спектр услуг для оптимизации и автоматизации ваших бизнес процессов. </p>
          </div>
          <div>
            <template v-for="(card, index) in info">
              <div
                :key="'address-' + index"
                class="pt-2 mb-0 d-flex"
              >
                <v-icon large>
                  {{ card.icon }}
                </v-icon>
                <div class="ml-3">
                  <h3>{{ card.title }}</h3>
                  <div>{{ card.text }}</div>
                </div>
              </div>
              <v-divider
                v-if="info.length > index"
                :key="'divider-' + index"
                dark
              />
            </template>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-divider
            vertical
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-card
            elevation="0"
            min-height="250"
          >
            <div
              class="pt-8 mb-0 d-flex"
              style="font-size: large"
            >
              <v-icon large>
                mdi-file
              </v-icon>
              <a
                href="/files/apiuz/userguide.pdf"
                class="ml-4 download-links"
                target="_blank"
                style="text-decoration: none; color: burlywood;"
              >
                Иструкция пользователя
              </a>
            </div>
            <div
              class="pt-3 mb-0 d-flex"
            >
              <v-icon large>
                mdi-file
              </v-icon>
              <a
                href="/files/apiuz/installguide.pdf"
                class="ml-4 download-links"
                target="_blank"
                style="text-decoration: none; color: burlywood;"
              >
                Руководство по установке на ПК
              </a>
            </div>
            <div
              class="pt-3 mb-0 d-flex"
            >
              <v-icon large>
                mdi-file
              </v-icon>
              <a
                href="/files/apiuz/pricelist.pdf"
                class="ml-4 download-links"
                target="_blank"
                style="text-decoration: none; color: burlywood;"
              >
                Расчет стоимости и варианты установки
              </a>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',
    data: () => ({
      map: {
        lat: '54.718915',
        lon: '20.539566',
      },
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
      info: [
        {
          title: 'Телефон',
          icon: 'mdi-cellphone',
          text: '8-4012-61-5838',
          type: 'phone',
        },
        {
          title: 'email',
          icon: 'mdi-email ',
          text: 'info@itmuse.ru',
          type: 'mail',
        },
      ],
    }),
    computed: {
      compHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? '660px' : '280px'
      },
    },
  }
</script>

<style>
  .download-links {
    font-size: large;
    text-transform: uppercase;
  }
</style>
